// Override default variables before the import
// you can open the bootstrap.scss module and check any available variables.
$primary: #527361;
$secondary: #80bc9c;
$success: #3e8d63;
$info: #7854e4;
$warning: #b8c924;
$danger: #d62518;
$light: #f8f9fa;
$dark: #343a40;

//custom color to be mapped
$accent : #da6d25;

// this is used to map colors. change accordingly.
$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $dark,
// add any additional color below
  accent: $accent,
// now bg-accent,btn-accent,etc.. can be called
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';