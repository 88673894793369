body {
  margin: 0;
  font-family: monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form-check-input, .form-check-inline {
  margin-top: 0.25em !important;
}

.case-title {
  padding: 0.5em;
}

.case-text {
  padding: 1.0em;
}

.case-link {
  padding: 1.0em;
}

.case-expand {
  padding: 1.0em;
}

.translator {
  color: gray;
}

.buy-it {
  display: block;
  padding: 0px 0px 10px 20px;
  font-size: 75%;
}

/* lineage chart */
.section {
    margin-top: 1em;
    margin-left: 1em;
}

.book-link {
    font-size: xx-small;
}

.book-label {
    font-size: xx-small;
    display: inline-block;
    width: 40px;
}

.chart-container {
    background-color: #303030;
    padding: 0;
}

.full-name {
    display: inline-block;
    padding-right: 0.5em;
    font-size: large;
}

.date {
    font-size: xx-small;
    padding-bottom: 0.4em;
}

.ch-name {
    display: inline-block;
}

.ch-sound {
  padding-left: 0.5em;
}

.wg-name {
    font-size: xx-small;
}

.jp-name {
    font-size: xx-small;
}